.home {
    /* position: absolute; */
    top: 0px;
    background-color: #EDFCFB;

}

.slick-prev {
    left: -14px;
}

.slick-next {
    right: -2px;
}

.slick-prev,
.slick-next {
    z-index: 1 !important;

}

.slick-prev:before,
.slick-next:before {
    font-family: 'Font Awesome 5 Free' !important;
    box-shadow: 0px 4.64px 3.94px 0px #00000012;
    color: #000000 !important;
    background-color: white;
    padding: 10px 12px;
    display: inline !important;
    border-radius: 30px;

}

.slick-prev:before {
    display: flex;
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 600 !important;
    content: '\f060' !important;
}

.slick-next:before {
    display: flex;
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 600 !important;
    content: '\f061' !important;
}

.banner {
    position: relative;
    z-index: 1;
}



.banner .bg-img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.transition-transform {
    transition-timing-function: ease-in-out;
    height: 100%;
}

.transform-o {
    transition-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.App-sc {
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    overflow-y: visible;
    position: relative;
    height: unset;
}



.App-sc {
    overflow-x: hidden;
    margin: 0;
}

.container {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

.firstContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: yellow;
}

.lastContainer {
    display: flex;
    height: 100vh;
    background: rgb(255, 0, 208);
}

.panel {
    width: 30vw !important;
    height: 100vh;
}

.container {
    width: 24vw !important;
}

.faq {
    background-image: url(../img/bg.jpg);
    background-size: cover;
    background-position: bottom;
}


.zoom-in,
.zoom-out {
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        background-size: cover;
        transform-origin: center;
        transition: transform .4s ease-in-out;
    }
}

.zoom-in {

    &:focus,
    &:hover {
        &::after {
            transform: scale(1.05);
        }
    }
}

.zoom-in-out-box {
    margin: 24px;
    width: 50px;
    height: 50px;
    background: #f50057;
    animation: zoom-in-zoom-out 1s ease infinite;
}

.Whats {
    position: fixed;
    bottom: 120px;
    right: 20px;
    z-index: 100;
  }
  .Whats img {
    background-color: #d0d7dc;
    padding: 5px;
    border-radius: 100%;
  }
  



@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.5, 1.5);
    }

    100% {
        transform: scale(1, 1);
    }
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {}



@media (max-width: 992px) {
    .panel {
        width: 40vw !important;
        height: 100vh !important;
    }
}

@media (max-width: 768px) {

    .logo {
        width: 200px;
    }

    .cursor {
        display: none !important;
    }

    .slick-prev {
        display: none !important;
    }

    .slick-next {
        display: none !important;
    }

}


@media (max-width: 576px) {
    .panel {
        width: 100vw !important;
        height: 100vh !important;
    }

    .container {
        width: 900vw !important;
    }

}

.think-bg {
    background-image: url(../img/think-bg.png);
    background-size: cover;
}










/* .parallax-section {
    width: 100%;
    height: 100vh; 
    position: relative;
  }
   */
.section-bg {
    /* width: 100vw; */
    height: 100vh;
    display: flex;
    position: sticky;
    top:0;
    align-items: center;
    transition: all 0.6s ease-in-out;

}
