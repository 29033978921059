@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'fontello';
  src: url('./font/icon/fontello.eot');
  src: url('./font/icon/fontello.eot') format('embedded-opentype'),
    url('./font/icon/fontello.woff') format('woff'),
    url('./font/icon/fontello.ttf') format('truetype'),
    url('./font/icon/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


@layer base {

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/font/GILROY-THIN.woff') format('woff');
    font-weight: 200
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/font/GILROY-LIGHT.woff')format('woff');
    font-weight: 300
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/font/GILROY-MEDIUM.woff') format('woff');
    font-weight: 400
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/font/GILROY-BOLD.woff') format('woff');

    font-weight: 600
  }


  @font-face {
    font-family: 'Gilroy';
    src: url('/src/font/GILROY-EXTRABOLD.woff') format('woff');
    font-weight: 700
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/font/GILROY-BLACK.woff') format('woff');
    font-weight: 800
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('/src/font/GILROY-HEAVY.woff') format('woff');
    font-weight: 900
  }

  body {
    cursor: url('/src/icon/icon.png'), auto;
  }

  p {
    font-family: 'Gilroy';
    font-weight: 400;
    @apply text-base
  }

  h1,
  h2 {
    font-family: 'Gilroy';
    font-weight: 900
  }

  h3 {
    font-family: 'Gilroy';
    font-weight: 700
  }

  h5 {
    font-family: 'Gilroy';
    font-weight: 300
  }

  a {
    font-family: 'Gilroy';
    font-weight: 300
  }

  label {
    font-family: 'Gilroy';
    font-weight: 300;
    @apply text-lg

  }

  small {
    font-family: 'Gilroy';
    font-weight: 300;
    @apply text-sm

  }



  .custom-cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);

    /* Ensures the cursor doesn't interfere with mouse events */
    z-index: 9999;
    /* Ensures the cursor is on top of other elements */
    transition: transform 0.3s ease-out;
    /* Example transition */
  }

  .cursor-inner {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: cursor-pulse 1s infinite;
    /* Example animation */
  }

  @keyframes cursor-pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

}

@layer components {
  .big-text {
    font-family: "Gilroy";
    font-weight: 900;
    color: #202020;
    @apply text-5xl lg:text-8xl font-extrabold sm:pb-16 pb-6 mx-auto sm:w-8/12 text-center lowercase
  }

  .short-note {
    @apply text-2xl py-4 pt-28
  }

  .short-note p {
    color: #1B1819;
    @apply pb-6 text-lg sm:text-2xl text-center sm:w-4/5 mx-auto
  }

  .main-section {
    @apply px-4 py-8 md:section-p-sm
  }

  .scroll-icon {

    z-index: 1;
    left: 50%;
    transform: translate(-50%, -50%) !important;

    @apply absolute bottom-4
  }

  .scroll-icon img {
    width: 95%;
    margin: 0 auto;
    z-index: -1;
  }
  .flp-side-main {
  margin: 1em;
  cursor: pointer;
}

.fix-icon {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 4;
}
.flp-side-main {
  margin: 1em;
  cursor: pointer;
}

.flpcircle {
  position: relative;
}

.downarrow {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  /* font-weight: 100; */
}

.noise-red{
  background:linear-gradient(321deg, rgba(236, 18, 55, 0) 50%, rgba(236, 18, 55, 1) 100%);
  border-radius: 0 0px 900px 0;
  top: 0px;
  left: 0px;
  width: 25rem;
  height: 25rem;
}
.noise-yellow{
  background: linear-gradient(51deg, rgb(255 199 0 / 0%) 62%, rgba(255, 199, 0, 1) 100%);
  border-radius: 0 0 0px 900px;
  top: 0px;
  right: 0px;
  width: 15rem;
  height: 20rem;
}
.noise-red-sm{
  background:linear-gradient(321deg, rgba(236, 18, 55, 0) 50%, rgba(236, 18, 55, 1) 100%);
  border-radius: 0 0px 900px 0;
  top: 0px;
  left: 0px;
  width: 15rem;
  height: 15rem;
}
.noise-yellow-sm{
  background: linear-gradient(59deg, rgb(255 199 0 / 0%) 70%, rgba(255, 199, 0, 1) 100%);
  border-radius: 0 0 0px 900px;
  top: 0px;
  right: 0px;
  width: 10rem;
  height: 20rem;
}
/* Spin Animation */

.txtcircle {
  display: flex;
  justify-content: center;
}

.drone-bg {
  display: flex;
  justify-content: center;
}

.drone {
  justify-content: center;
  display: flex;
}

.txtcircle {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.68, -.55, 0.265, 1.55);
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}











  .white-sub-head {
    @apply text-white xl:text-5xl text-3xl font-bold  
  }

  .primary-sub-head {
    @apply text-primary-s xl:text-5xl text-3xl font-bold
  }

  .dark-sub-head {
    @apply text-[#272727] xl:text-5xl text-3xl font-bold
  }

  .dot-data {
    bottom: 0;
    right: 45%;
    @apply absolute hidden sm:block
  }



  /* button */
  .btn {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.575rem 2.5rem;
    border-radius: 30px;
  }

  .pin-spacer {
    background-color: #000000 !important;
  }

  /* .btn-primary {
    color: #fff;
    cursor: pointer;
    display: block;
    position: relative;
    background-color: #16161F;
    border: 2px solid #16161F;
  }*/


  .btn-primary {
    position: relative;
    cursor: pointer;
    margin: auto;
    width: 100%;
    max-width: 260px;
    /* height: 52px; */
    background-color: #16161F;
    color: #ffffff;
    transform: translate3d(0px, 0%, 0px);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.05em;
    transition-delay: 0.4s;
    overflow: hidden;
    border: 2px solid #16161F;
    transition: all 0.6s ease;

  }

  .btn-primary:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
    transition: all 0.6s ease;

  }

  .btn-primary:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #16161F;
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all 0.6s ease;

  }

  .btn-primary:hover {
    color: #16161F !important;
    font-weight: 600;
    letter-spacing: 0.05em;

    background-color: transparent;
    border: 2px solid #16161F;
    z-index: 0;
  } 


  .btn-primary:hover:after {
    border-radius: 0 0 50% 50%;
    transform: translateY(-100%) scaleY(0.5);
    transition-delay: 0;

  }

  .btn-primary:hover:before {
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition-delay: 0;

  }

  .btn-secondry {
    position: relative;
    cursor: pointer;
    margin: auto;
    width: 100%;
    max-width: 260px;
    /* height: 52px; */
    background: white;
    color: #000000;
    transform: translate3d(0px, 0%, 0px);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.05em;
    transition-delay: 0.3s;
    overflow: hidden;
    transition: all 0.6s ease;

  }

  .btn-secondry:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    color: #000000;
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
    transition: all 0.6s ease;
  }

  .btn-secondry:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all 0.6s ease;

  }

  .btn-secondry:hover {
    background-color: #16161F;
    transition: background 0.2s linear;
    color: #ffffff;
    letter-spacing: 0.05em;

    transition-delay: 0.6s;
  }

  .btn-secondry:hover:after {
    border-radius: 0 0 50% 50%;
    transform: translateY(-100%) scaleY(0.5);
    transition-delay: 0;

  }

  .btn-secondry:hover:before {
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition-delay: 0;
  }

  .small-title {
    @apply text-lg font-medium
  }

  /* .btn-secondry {
    color: #000000;
    cursor: pointer;
    display: block;
    position: relative;
    background-color: #ffffff;
    border: 2px solid #ffffff;
  } */

  /* .btn-primary:hover {
    color: #16161F;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid #16161F;
    z-index: 0;
  } */

  .btn-yellow {
    position: relative;
    cursor: pointer;
    margin: auto;
    width: 100%;
    max-width: 260px;
    height: 52px;
    background: linear-gradient(176deg, rgb(255 179 0) 0%, rgba(255, 199, 0, 1));
    color: #000000;
    transform: translate3d(0px, 0%, 0px);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.05em;
    transition-delay: 0.6s;
    overflow: hidden;
  }

  .btn-yellow:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;

    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
    transition: all 0.6s ease;
  }

  .btn-yellow:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(176deg, rgb(255 179 0) 0%, rgba(255, 199, 0, 1));
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all 0.6s ease;
  }

  .btn-yellow:hover {
    background: #ffffff;
    transition: background 0.2s linear;
    transition-delay: 0.6s;
  }

  .btn-yellow:hover:after {
    border-radius: 0 0 50% 50%;
    transform: translateY(-100%) scaleY(0.5);
    transition-delay: 0;
  }

  .btn-yellow:hover:before {
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition-delay: 0;
  }

  .btn-round {
    position: relative;
    cursor: pointer;

    width: 100%;
    max-width: 260px;
    background: #ffffff1a;
    color: #ffffff;
    transform: translate3d(0px, 0%, 0px);
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.05em;
    transition-delay: 0.6s;
    overflow: hidden;

  }

  .btn-round:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    color: #ffffff;
    border-radius: 50% 50% 0 0;
    transform: translateY(100%) scaleY(0.5);
    transition: all 0.6s ease;
  }

  .btn-round:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition: all 0.6s ease;

  }

  .btn-round:hover {
    background: rgb(0, 0, 0);
    color: #ffffff;



    transition: background 0.2s linear;
    transition-delay: 0.6s;
  }

  .btn-round:hover:after {
    border-radius: 0 0 50% 50%;
    transform: translateY(-100%) scaleY(0.5);
    transition-delay: 0;
  }

  .btn-round:hover:before {
    border-radius: 0;
    transform: translateY(0) scaleY(1);
    transition-delay: 0;
  }

  /* 
  .btn-yellow {
    color: #000000;
    cursor: pointer;
    display: block;
    position: relative;
    background: rgb(255, 199, 0);
    background: linear-gradient(176deg, rgb(255 179 0) 0%, rgba(255, 199, 0, 1));
    border: 2px solid #FFC700;
    position: relative;

  } */

  /* 
  .btn-yellow:hover {
    color: #16161F !important;
    font-weight: 500;
    background-color: transparent;

    z-index: 0;
    font-size: 16px;
    font-weight: 600;
  } */

  /* 

  .btn-yellow {
    border: 1px solid;
    overflow: hidden;
    position: relative;
    span {
      z-index: 20;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: .3;
      position: absolute;
      top: -50px;
      transform: rotate(35deg);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      width: 80px;
      z-index: -10;
    }
  }

  .btn-yellow:hover {

    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
 */
  .card-services {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    @apply sm:py-20 py-6 px-4 text-neutral-50 flex
  }

  .card-services .card-head {
    font-family: 'Gilroy';
    font-weight: 800;

    line-height: 1;
    background: -webkit-linear-gradient(168deg, #777777 0.86%, #FFFFFF 99.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @apply text-5xl sm:text-6xl font-black mb-4 mt-2 pb-2
  }

  .white-line {
    width: 100%;
    height: 0.6px;
    background-color: #FFFFFF;
  }

  .card-services p {
    color: #E7E4E5;
    @apply md:text-lg sm:text-base
  }

  .card-services-1 {
    background-image: url('../src/img/services/1.png');
  }

  .card-services-2 {
    background-image: url('../src/img/services/2.png');
  }

  .card-services-3 {
    background-image: url('../src/img/services/3.png');
  }

  .card-services-4 {
    background-image: url('../src/img/services/4.png');
  }

  .card-services-5 {
    background-image: url('../src/img/services/5.png');
  }

  .panel:hover {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(178%);
  }

  .card-what {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    @apply sm:p-4 p-2 h-60 sm:h-96 text-neutral-50 flex items-end
  }

  .card-what:nth-child(1) {
    background-image: url('../src/img/image/1.png');
  }

  .card-what:nth-child(2) {
    background-image: url('../src/img/image/2.png');

  }

  .card-what:nth-child(3) {
    background-image: url('../src/img/image/3.png');

  }

  .card-what:nth-child(4) {
    background-image: url('../src/img/image/4.png');

  }

  .card-what:nth-child(5) {
    background-image: url('../src/img/image/5.png');

  }

  .card-what:nth-child(6) {
    background-image: url('../src/img/image/6.png');

  }

  .card-what:nth-child(7) {
    background-image: url('../src/img/image/7.png');

  }

  .card-what:nth-child(8) {
    background-image: url('../src/img/image/8.png');
  }

  .span-icon {
    font-family: 'fontello';

  }

  /* pages */
  .sub-page-head {
    @apply text-white text-center text-4xl sm:text-6xl
  }

  .our-kreators .sub-banner {
    background-image: url(../src/img/page/OurKreatorsBg.png);
    background-size: cover;
    background-position: center bottom;
    @apply h-[70vh] md:h-[70vh] relative w-full
  }

  .information .sub-banner {
    background-image: url(../src/img/page/faq/fq-bg.png);
    background-size: cover;
    background-position: center bottom;
    @apply h-[70vh] md:h-[70vh] relative w-full
  }

  .servies .sub-banner {
    background-image: url(../src/img/page/servies/servies-bg.png);
    background-size: cover;
    background-position: center bottom;
    @apply h-[70vh] md:h-[70vh] relative w-full
  }

  .portfolio .sub-banner {
    background-image: url(../src/img/page/Portfolio/Portfolio-bg.png);
    background-size: cover;
    background-position: center bottom;
    @apply h-[70vh] md:h-[70vh] relative w-full
  }

  .portfolio-about {
    border-top: 1px solid black;
  }

  .highlight-work-one {
    background-image: url(../src/img/page/Portfolio/highlight/1.png);
    background-size: cover;
    @apply sm:h-[500px] h-[200px]
  }

  .highlight-work-two {
    background-image: url(../src/img/page/Portfolio/highlight/2.png);
    background-size: cover;
    @apply sm:h-[500px] h-[200px]
  }

  .highlight-work-three {
    background-image: url(../src/img/page/Portfolio/highlight/3.png);
    background-size: cover;
    @apply sm:h-[500px] h-[200px]
  }

  .dark-bg-g {
    background: radial-gradient(circle, rgba(5, 0, 0, 1) 0%, rgba(12, 0, 0, 1) 100%);
  }

  .white-bg-g {
    background-color: white;
  }

  .product-img {
    border-radius: 0;
    /* Initial border radius */
    transition: border-radius 0.3s ease;
    height: 440px;
    width: 100%;
    object-fit: cover;

  }

  .product-img:hover {
    border-radius: 62px;
  }

  .portfolio-images {
    background-image: url(../src/img/page/Portfolio/portfolio-bg-pro.png);
    background-size: cover;

  }



  .sub-banner .text-body {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @apply absolute
  }

  .sub-banner .banner-botton {
    width: 100%;
    @apply absolute bottom-0 text-white px-4 md:px-20 pb-5
  }

  .sub-banner .banner-botton a {
    border-bottom: 1px solid rgb(255, 255, 255);
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 10px;

  }

  .process {
    background-image: url(../src/img/page/our-kreators-pg-bg.png);
    @apply text-white
  }

  .process p {
    @apply  font-light text-base
  }

  .bg-founder {
    background-image: url(../src/img/founder-bg.png);

  }

  .group-bg {
    background-image: url(../src/img/page/pr-working.png);
    background-size: cover;
  }

  .blog-bg {
    background-image: url(../src/img/blog/blog.png);
    background-size: cover;
  }

  .worker-card {
    border: 1px solid #FFFFFF
  }

  .worker-card img {
    width: 100%;

  }
  

  .information-bg {
    background-image: url(../src/img/page/faq/info-bg.png);
    background-size: cover;
 
  }

  .gradient-bg {
    background:linear-gradient(186deg, #060103bf 0%, #060103 100%);
    height:138px;
    @apply absolute bottom-0 w-full

  }

  .worker-info {
    background-color: #271C1C;
    /* padding: 8px 34px 14px 34px; */
    @apply sm:pl-8 sm:pr-8 sm:pt-2 sm:pb-3.5 p-3 bg-[#271C1C];
  }

  .blog-info {
    @apply bg-[#060606] text-white sm:pl-6 sm:pr-6 sm:pt-2 sm:pb-3.5 p-3
  }

  .blog-info .read-more {
    border-top: 1px solid white;
  }

  .blog-info small {
    @apply text-[#4A4A4A] font-semibold
  }

  .worker-info h5,
  h6 {
    font-size: 16px;
    font-weight: 400;


  }

  .service-video {
    @apply relative
  }

  .service-video .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 45px;

    @apply absolute text-white
  }

  .about-service {
    background-image: url(../src/img/page/servies/about-servies-bg.png);
  }

  .work {
    background-size: cover;
    

    @apply xl:h-[400px] h-[150px] 
  }

  


  .work-one {
    background-image: url(../src/img/page/servies/works/image-1.png);
  }

  .work-two {
    background-image: url(../src/img/page/servies/works/image-2.png);
  }

  .work-three {
    background-image: url(../src/img/page/servies/works/image-3.png);
  }

  .work-four {
    background-image: url(../src/img/page/servies/works/image-4.png);
  }

  .work-five {
    background-image: url(../src/img/page/servies/works/image-5.png);
  }

  .work-six {
    background-image: url(../src/img/page/servies/works/image-6.png);
  }
  .work-seven {
    background-image: url(../src/img/page/servies/works/image-7.png);
  }
  .work-eight {
    background-image: url(../src/img/page/servies/works/image-8.png);
  }


  .works-service {
    background-image: url(../src/img/page/servies/work-bg.png);
    background-size: cover;
  }

  /* footer */

  .footer {
    @apply bg-[#12121C] text-white
  }

  .footer li a {
    @apply py-2 sm:text-lg
  }

  .footer li {
    @apply py-1
  }

  .footer-sub-head {
    @apply text-xl sm:text-2xl font-bold mt-6 sm:mt-8
  }
}






@layer utilities {
  .section-p-sm {
    padding: 90px 5rem;
  }

  .section-p {
    padding: 60px 4rem;
  }

}