#menu-toggle:checked+#menu {
  display: block;
}

.navicon {
  width: 1.125em;
  height: .125em;
}

.navicon::before,
.navicon::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all .2s ease-out;
  content: '';
  background: #ffff;
}

.navicon::before {
  top: 5px;
}

.navicon::after {
  top: -5px;
}

.menu-btn:not(:checked)~.menu {
  display: none;
}

.menu-btn:checked~.menu {
  display: block;
}

.menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

.menu-btn:checked~.menu-icon .navicon::before {
  transform: rotate(-45deg);
}

.menu-btn:checked~.menu-icon .navicon::after {
  transform: rotate(45deg);
}

.menu-btn:checked~.menu-icon .navicon::before,
.menu-btn:checked~.menu-icon .navicon::after {
  top: 0;
}

.close-btn {
  background-color: white;
  border-radius: 40px;
  margin-bottom: 6px;
}

.filterCssOp {
  filter: contrast(40%) brightness(80%)
}

.filterCssFull {
  filter: contrast(500%) brightness(550%)
}

.transition-transform {
  transition-timing-function: ease-in-out;
  height: 100%;
}

.transform-o {
  transition-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.bg-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1C042F;
}

.loader-icon {
  width: 300px;
  height: 300px;
  position: absolute;
  top: -2px;

}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  background-color: #0d0017;
  z-index: 100;
  padding-top: 150px;

}

.loader .text-data {
  color: rgb(220, 220, 220);
  z-index: 200;
  padding-top: 25px;
  font-weight: 600;

}

.loader-img {
  position: relative;

}

.loader img {
  z-index: 200;
  opacity: 0.2
}



.testimonial-card video {
  width: 100%;
  height: 250px;
  border-radius: 40px;
  display: block;
  padding: 10px;
}
.nav li a{
  font-size: 16px;
  font-family: 'Gilroy';
  font-weight: 400

}

.card-contact {
  /* padding: 30px; */
  border: 1px solid #f7f7f7;
  border-radius: 20px;
  /* margin: 40px auto; */
  width: 70%;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* margin-top: 20px; */
  margin: 0 auto !important;
  @apply p-[10px] sm:p-[30px] sm:mx-[40px] mx-[auto] w-[95%] sm:w-[70%] mt-[100px] 
}
.card-contact-full {
  width: 100%;
  @apply sm:px-[30px] px-4

}

.card-contact label {
  font-weight: 500;
}

.card-contact h4 {
  /* font-size: 2rem; */
  font-weight: 600;
  line-height: 0.1;
  @apply sm:text-[2rem] text-[1.5rem] 
}


@media (max-width: 767.98px) {

  .nav-sm {
    flex-direction: row-reverse;
    height: 100vh;
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.73);

  }

  .nav-sm ul li {
    color: #eaeaea;
    border-bottom: none;
    text-align: center;
    font-size: 18px;
  }

  .nav-sm ul li a {
    font-size: 20px;
  }

  .nav-sm .nav-menu {
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 13px;
  }

  .nav img {
    width: 56px;
  }



}