/* Define the default cursor style */
.custom-cursor {
  position: fixed;
  width: 25px;
  height: 25px;
  border: 2px solid #BE0E2C;
  border-radius: 50%;
  background-color: transparent;
  pointer-events: none; /* Ensure cursor doesn't interfere with mouse events */
  z-index: 9999; /* Ensure cursor is on top of other elements */
  transition: transform 0.1s ease; /* Add a smooth transition effect */

  /* Define the default cursor animation */
  animation: defaultCursorAnimation 0.5s infinite;
}

/* Define keyframes for the default cursor animation */
@keyframes defaultCursorAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Define the cursor style when hovering over text */
.hover-text {
  cursor: pointer; /* Change cursor to pointer on hover */
}

/* Define the cursor animation when hovering over text */
.hover-text:hover .custom-cursor {
  width: 40px; /* Change cursor size */
  height: 40px; /* Change cursor size */
  animation: hoverCursorAnimation 0.5s infinite; /* Use a different animation */
}

/* Define keyframes for the cursor animation when hovering over text */
@keyframes hoverCursorAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {

  .custom-cursor {
      display:none !important;
  }

}