.slider {
    height: 60px;
    position: relative;
    width: 100%;
  z-index: 0;
    display: grid;
    place-items: center;
    overflow: hidden;
  }
  .slider::before,
  .slider::after{
    position:absolute;
    background-image:linear-gradient(to right,rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    content:'';
    height:100%;width:25%;
    z-index:2;pointer-events:none;
  }
  .slider::before{
    left:0;
    top:0;
  }
  .slider::after{
    right:0;
    top:0;
    transform:rotateZ(180deg);
  }
  
  /*  IMPORTANT CODE BELOW */
  
  .slide-track {
    width: calc(230px * 20);
    display: flex;
    animation: scroll 30s linear infinite;
    justify-content: space-between;
  }
  
  .slide-track2 {
    width: calc(230px * 20);
    display: flex;
    animation: scroll2 30s linear infinite;
    justify-content: space-between;
  }
  
  .slide {
    width: 165px;
    height: 60px;
  
    display: grid;
    place-items: center;
    transition:0.5s;
    cursor:pointer;
  transform: scale(0.8);
  }
  .slide:hover{
    transform:scale(0.6)
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-150px * 10));
    }
  }
  
  @keyframes scroll2 {
    0% {
      transform: translateX(calc(-150px * 10));

    }
    100% {
        transform: translateX(0px);
    }
  }
  
  @media screen and (max-width: 768px) {
    .slide-track {
      width: calc(120px * 20);
    }
  
    .slide-track2 {
      width: calc(120px * 20);
    }
  
    .slide {
      width: 120px;
    }
  
    @keyframes scroll {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(calc(-80px * 10));
      }
    }
  
    @keyframes scroll2 {
      0% {
        transform: translateX(calc(-80px * 5));

      }
      100% {
                transform: translateX(0px);

      }
    }
  }
  
  .circle-loader{
    background-color: #faeeef;
    padding: 6px;
    border-radius: 100px;
    z-index: 10000;
  }