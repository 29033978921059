.thanks-msg h5 {
    font-family: 'gilroy';
    font-size: 3.5em;
    line-height: 1em;
    font-weight: 600;
    font-variant-ligatures: no-common-ligatures;
    color: #212529;
}


.thanks-msg h6 {
    font-family: 'gilroy';
    font-size: 2.5em;
    line-height: 1em;
    font-weight: 600;

    font-variant-ligatures: no-common-ligatures;
    color: #212529;

}