/* body {
  background-color: #000;
} */

.page-animation {
  position: absolute;
    width:100%;
    height: 55%;
    overflow: hidden;
    bottom: -175px;
}
.page-animation .scroll {
  background-color: #E7E4E5;

}

.page-animation .scroll {
  width: 100%;
  display: flex;
}

/* .text2{
  border-top: 5px solid #9F9397;
  border-bottom: 5px solid #9F9397;
} */

.page-animation .scroll div {
  background-color: #E7E4E5;
  color: #333333;
  font-size: 2.15em;
  font-weight: 900;
  white-space: nowrap;
  text-transform: lowercase;
  animation: animate 40s linear infinite;
  animation-delay: -40s;
  border-top: 5px solid #9F9397;
  border-bottom: 5px solid #9F9397;
  font-family: "Aclonica", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 20px 0px;
  gap:45px
 
}

.page-animation .scroll div:nth-child(2) {
  animation: animate2 40s linear infinite;
  animation-delay: -20s;
 

}
.page-animation .scroll div:nth-child(3) {
  animation: animat3 40s linear infinite;
  animation-delay: -10s;
 

}

.page-animation .scroll div span {
 width: 100%;
}
/* .special{
  color:#E7E4E5 ;
  font-size: 20px;
} */

@keyframes animate {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);

  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0%);

  }

  100% {
    transform: translateX(-200%);
  
  }
}

@keyframes animate3 {
  0% {
    transform: translateX(0%);

  }

  100% {
    transform: translateX(-400%);
  
  }
}


.page-animation .scroll.text1 {
  transform: rotate(2deg) translateY(150px) translateX(-300px);
  box-shadow: 20px -2px 15px rgba(0, 0, 0, 0.5);
  background-color: #e7e4e5;

}

.page-animation .scroll.text2 {
  transform: rotate(-5deg) translateY(79px) translateX(-300px);
 
}



@media (max-width: 575.98px) {}

@media (max-width: 767.98px) {
  .page-animation {
    position: absolute;
    height:50%;
    bottom: -130px;
  }
  .page-animation .scroll.text1 {
    transform: rotate(5deg) translateY(150px) translateX(-300px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .page-animation .scroll.text2 {
    transform: rotate(349deg) translateY(79px) translateX(-300px);}
  .page-animation .scroll div {
    font-size: 1.5em;
  }
  .section-bg {
    /* width: 100vw; */
    height: 70vh;
    display: flex;
    position: sticky;
    top:0;
    transition: all 0.5s ease-out;
    align-items: center;

}


}

@media (max-width: 991.98px) {}

@media (max-width: 1199.98px) {}

@media (max-width: 1399.98px) {}




 

 